<template>
  <b-row>
    <b-col cols="12">
      <template>
        <!-- <div class="card">
            <b-card-body> </b-card-body>
          </div> -->
        <div class="card">
          <div class="px-2 pt-2 d-flex flex-wrap justify-content-between">
            <div class="info-left w-30 d-flex">
              <router-link class="mr-1" to="/niche-manager">
                <feather-icon
                  class="text-primary"
                  icon="ArrowLeftCircleIcon"
                  size="30"
                />
              </router-link>
              <h3>Top Apps with 10K in the last 7 days | Last 30 days</h3>
            </div>
          </div>
          <div v-if="result && !progress && items.length > 0" class="mb-2">
            <div class="d-flex align-items-center tagWrappper">
              <feather-icon
                class="text-primary m-1"
                icon="FilterIcon"
                size="32"
              />
              <b-form-tags
                tag-variant="warning"
                class="m-1"
                placeholder="Filter or search by keyword"
                input-id="tags-basic"
                v-model="typedNiches"
              >
              </b-form-tags>
            </div>
          </div>
          <!--:sort-by="sortBy"-->
          <b-table
            striped
            hover
            responsive
            class="position-relative mb-0"
            :per-page="perPage"
            :current-page="currentPage"
            :items="isFiltered ? filteredResult : items"
            :fields="fields"
            show-empty
            v-if="result && !progress"
          >
            <template #empty>
              <div class="text-center" v-if="!isFiltered">
                <feather-icon icon="AlertTriangleIcon" class="" size="60" />
                <h3 class="mt-1">Data Being Prepared</h3>
              </div>
              <div class="text-center" v-else>
                <feather-icon icon="AlertTriangleIcon" class="" size="60" />
                <h3 class="mt-1">No data was Found for this Niche</h3>
              </div>
            </template>
            <template #cell(icon)="data">
              <img :src="data.value" width="30" />
            </template>
            <template #cell(scoreText)="data">
              {{ data.value ? `${data.value} / 5` : 0 }}
            </template>
            <template #cell(released)="data">
              <span v-if="data.value">
                {{ moment(data.value).format("D, MMM YYYY") }}</span
              >
            </template>
            <template #cell(created_at)="data">
              <span v-if="data.value">
                {{ moment(data.value).format("D, MMM YYYY") }}</span
              >
            </template>
            <template #cell(version)="data">
              <span
                class="app-score badge bg-primary rounded-pill px-1 overflow-hidden"
                style="max-width: 60px; text-overflow: ellipsis"
                >{{ data.value }}</span
              >
            </template>
            <template #cell(title)="data">
              <router-link
                style="color: inherit"
                :to="{
                  name: 'apps-info',
                  params: { id: data.item.appId, from: 'niches', route: 7 },
                }"
                target="_blank"
              >
                {{ data.item.title }}
              </router-link>
            </template>
            <template #cell(account_id)="data">
              <router-link
                class="ellipsis-link"
                :to="{
                  name: 'market-manager-accounts-search',
                  query: { search: data.item.account_id },
                }"
                target="_blank"
              >
                {{ data.item.account_id }}
              </router-link>
            </template>
            <template #cell(playstoreUrl)="data">
              <div class="d-flex" style="gap: 10px">
                <p>
                  <a
                    :href="`https://play.google.com/store/apps/details?id=${data.item.appId}`"
                    target="_blank"
                    title="Visit on Store"
                    ><feather-icon icon="EyeIcon" size="20"
                  /></a>
                </p>
                <p>
                  <a
                    :href="
                      '/myprofile/apps/' + data.item.appId + ',niches,7/info'
                    "
                    target="_blank"
                    title="Show More Info"
                    class="text-warning"
                  >
                    <feather-icon icon="InfoIcon" size="20" />
                  </a>
                </p>
              </div>
            </template>
          </b-table>
          <div class="text-center my-4" v-if="progress">
            <b-spinner
              class="spinner"
              variant="primary"
              size="60"
              label="Text Centered"
            />
          </div>
          <b-row class="m-1">
            <b-col cols="6">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-25"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </div>
      </template>
    </b-col>
  </b-row>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  BFormTags,
  BIcon,
  CustomIcon,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BFormTags,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BIcon,
  },
  data() {
    return {
      value: "",
      moment,
      perPage: 100,
      pageOptions: [10, 50, 100, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: "released",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "icon",
          label: "Icon",
        },
        {
          key: "title",
          label: "Title",
        },
        {
          key: "scoreText",
          label: "Score",
        },
        {
          key: "maxInstalls",
          label: "Installs",
          sortable: true,
        },
        {
          key: "version",
          label: "Version",
        },
        {
          key: "account_id",
          label: "Account",
        },
        {
          key: "released",
          label: "Released",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Date of Discovery",
          sortable: true,
        },
        {
          key: "playstoreUrl",
          label: "Actions",
        },
      ],
      optionsCountries: [],
      optionsChart: [
        { value: "top_free", text: "Free" },
        { value: "top_new_apps", text: "New Free" },
      ],
      optionsType: [
        { value: null, text: "All" },
        { value: "GAME", text: "Games" },
        { value: "APPLICATION", text: "Applications" },
      ],
      optionsCategories: [{ value: "OVERALL", text: "Overall" }],
      chartSelected: "top_new_apps",
      CategorySelected: "OVERALL",
      typeSelected: null,
      chartSelectedText: "New Free",
      countryCode: "ma",
      countryName: "Morocco",
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      apps: [],
      items: [],
      filteredResult: [],
      typedNiches: [],
      preserveCustimizeNiche: [],
      isFiltered: false,
      niche1Count: 0,
      niche2Count: 0,
      niche3Count: 0,
      niche4Count: 0,
      totalNicheResult: null,
      counter: 0,
      result: true,
      progress: true,
      isDatabase: false,
    };
  },
  computed: {
    tagColor() {
      if (store.state.appConfig.layout.skin === "dark") {
        return "#000";
      }
      return "#fff";
    },
  },
  watch: {
    typedNiches: {
      handler(newVal) {
        this.filterByCustomizedNiche(newVal);
      },
      deep: true,
    },
    tagColor(oldValue, newValue) {
      const tags = document.querySelector(".b-form-tag");
      tags.style.color = newValue;
    },
  },

  mounted() {
    if (store.getters.getplanCheck) {
      this.getApps();
    } else {
      this.$router.push({ name: "error-404" });
    }
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async filterByNiche(word) {
      this.isFiltered == false;
      if (word !== "all") {
        this.isFiltered = true;
        this.typedNiches = this.typedNiches.concat([word]);
        this.typedNiches = [...new Set(this.typedNiches)];
        this.filterByCustomizedNiche(this.typedNiches);
      } else {
        this.typedNiches = [];
        this.filteredResult = this.items;
      }
    },
    async filterByCustomizedNiche(niches) {
      this.totalNicheResult = 0;
      this.isFiltered = true;
      let uniqueApps = new Set();
      if (niches.length > 0) {
        for (const niche of niches) {
          const promises = this.items.map(async (app) => {
            if (await app.title.toLowerCase().includes(niche.toLowerCase())) {
              return app;
            }
            return null;
          });
          const filteredApps = await Promise.all(promises);
          filteredApps.filter(Boolean).forEach((app) => {
            uniqueApps.add(app);
          });
        }
        this.filteredResult = Array.from(uniqueApps);
        this.totalNicheResult = this.filteredResult.length;
        this.preserveCustimizeNiche = this.filteredResult;
      } else {
        this.filteredResult = this.items;
      }
    },
    getApps() {
      this.items = [];
      this.totalRows = 0;
      this.progress = true;
      axios
        .get(`/get-top-10k-apps`)
        .then((response) => response.data)
        .then((data) => {
          this.progress = false;
          if (data.data.length > 0) {
            this.isDatabase = true;
            let apps = data.data.sort((a, b) => {
              return a.position - b.position;
            });

            this.displayTableContent(apps);
          }
        })
        .catch(console.error);
    },

    displayTableContent(results) {
      results.forEach((item) => {
        var timestamp;
        if (item.released) {
          timestamp = Date.parse(item.released);
        }
        item.released = timestamp;
      });
      this.apps = results;
      this.items = this.addIndexToTable(this.apps);
      const niches = ["coloring", "fakecall", "guide", "vpn"];
      niches.map((word, index) => {
        this[`niche${index + 1}Count`] = this.items.filter((apps) => {
          return apps.title.toLowerCase().includes(word.toLowerCase());
        }).length;
      });

      this.totalRows = this.items.length;
    },

    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;

        return item;
      });

      return t;
    },
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.filterBadgesWrapper {
  margin-left: 4.3rem;
}

.filterBadges {
  cursor: pointer;
  margin-inline: 0.6rem !important;
  padding: 0.3rem 0.6rem !important;
}

.filterBadges:hover {
  transform: scale(1.1);
}

.b-form-tag {
  background-color: transparent;
  font-size: 1rem !important;
  color: grey !important;
  border: 1px solid #aaaaaa !important;
  border-radius: 2rem;
  padding-inline: 10px !important;
}

.tagWrappper {
  display: flex !important;
  align-items: center !important;
}

.ellipsis-link {
  white-space: nowrap !important;
  display: block;
  width: 10rem !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ellipsis-link:hover {
  overflow: visible !important;
}
</style>
  